import { HorizontalBar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
        labels: {
          boxWidth: 20,
          padding: 15,
          fontSize: 13,
          fontFamily: 'Quicksand'
        }
      },
      tooltips: {
        bodyFontFamily: 'Quicksand'
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Toneladas de CO2e', // Label for x-axis
              fontFamily: 'Quicksand',
              fontSize: 13,
              fontStyle: 'bold',
              padding: {
                bottom: 25
              }
            },
            ticks: {
              beginAtZero: true,
              fontFamily: 'Quicksand'
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              fontFamily: 'Quicksand'
            },
            scaleLabel: {
              display: true,
              labelString: 'Instalación',
              fontFamily: 'Quicksand',
              fontSize: 13,
              fontStyle: 'bold'
            },
            gridLines: {
              display: false // Hide horizontal grid lines
            }
          }
        ]
      },
      tooltips: {
        enabled: true
      }
    }
  }),
  watch: {
    chartData: function (val) {
      this.renderChart(this.chartData, this.options);
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
