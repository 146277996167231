import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
        labels: {
          boxWidth: 20,
          padding: 15,
          fontSize: 13,
          fontFamily: 'Quicksand'
        }
      },
      tooltips: {
        bodyFontFamily: 'Quicksand'
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontFamily: 'Quicksand'
            },
            scaleLabel: {
              display: true,
              labelString: 'Toneladas de CO2e',
              fontFamily: 'Quicksand',
              fontSize: 13,
              fontStyle: 'bold'
            }
          }
        ]
      }
    }
  }),
  watch: {
    chartData: function (val) {
      this.renderChart(this.chartData, this.options);
    }
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  }
};
