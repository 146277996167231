<template>
  <v-container fluid fill-height align-center class="px-8 pt-15">
    <v-row>
      <v-col cols="12">
        <v-card min-height="250" min-width="300" class="pa-5" style="display: flex; align-items: center">
          <v-row>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
                <v-card-text class="text-center font-weight-bold" style="font-size: 20px; color: black">
                  Comparado con la industria
                </v-card-text>
                <v-card-subtitle style="font-size: 50px; text-align: center; color: rgb(8 124 23); font-weight: bold" class="pt-10">
                  5%
                </v-card-subtitle>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0, 0, 0)" class="pb-3">
                  menor que el promedio
                </v-card-text>
                <v-card-text style="text-align: center; font-weight: bold; font-size: 18px" class="pa-0"> Promedio: 1500 tCO2e </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
                <v-card-text class="text-center font-weight-bold" style="font-size: 20px; color: black"> Comparado con tu objetivo </v-card-text>
                <v-card-subtitle style="font-size: 50px; text-align: center; color: rgb(215 69 69); font-weight: bold" class="pt-10">
                  7%
                </v-card-subtitle>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0, 0, 0)" class="pb-3"> superior al objetivo </v-card-text>
                <v-card-text style="text-align: center; font-weight: bold; font-size: 18px" class="pa-0"> Objetivo: 1331 tCO2e </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
                <v-card-text class="text-center font-weight-bold" style="font-size: 20px; color: black"> Consumo energético </v-card-text>
                <v-card-subtitle style="font-size: 50px; text-align: center; color: rgb(215 69 69); font-weight: bold" class="pt-10">
                  5%
                </v-card-subtitle>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0, 0, 0)" class="pb-3">
                  superior al periodo pasado
                </v-card-text>
                <v-card-text style="text-align: center; font-weight: bold; font-size: 18px" class="pa-0"> Pasado: 500 kWh </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
                <v-card-text class="text-center font-weight-bold" style="font-size: 20px; color: black"> Emisiones compensadas </v-card-text>
                <v-card-subtitle style="font-size: 50px; text-align: center; color: rgb(69 73 215); font-weight: bold" class="pt-10">
                  450
                </v-card-subtitle>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0, 0, 0)" class="pb-3"> tCO2e </v-card-text>
                <v-card-text style="text-align: center; font-weight: bold; font-size: 18px" class="pa-0"> Equivalente: 225 árboles </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card min-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold"> Huella de Carbono de XY </v-card-title>
          <v-row>
            <v-col>
              <div class="pt-12 pb-5">
                <v-card-subtitle style="font-size: 50px; text-align: center; color: rgb(137 60 219); font-weight: bold" class="pt-15">
                  23,789
                </v-card-subtitle>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0, 0, 0)" class="pb-3"> tCO2e </v-card-text>
              </div>
              <v-card-text style="text-align: center; font-weight: bold; font-size: 12px" class="pt-10">
                Este valor es 13% superior a la huella de carbono del año pasado para el mismo periodo
              </v-card-text>
            </v-col>
            <v-col>
              <v-card-title>Alcance 1</v-card-title>
              <v-card-subtitle style="font-size: 50px; color: rgb(69 73 215); font-weight: bold" class="pt-2">
                20,789<span style="color: rgb(0, 0, 0); font-size: 14px"> tCO2e</span>
              </v-card-subtitle>
              <v-card-title>Alcance 2</v-card-title>
              <v-card-subtitle style="font-size: 50px; color: rgb(26 108 44); font-weight: bold" class="pt-2">
                500<span style="color: rgb(0, 0, 0); font-size: 14px"> tCO2e</span>
              </v-card-subtitle>
              <v-card-title>Alcance 3</v-card-title>
              <v-card-subtitle style="font-size: 50px; color: rgb(185 77 44); font-weight: bold" class="pt-2">
                2,500<span style="color: rgb(0, 0, 0); font-size: 14px"> tCO2e</span>
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card min-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Emisiones por categoría</v-card-title>
          <div class="container">
            <Doughnut />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12" md="6">
        <v-card min-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Emisiones a través del tiempo</v-card-title>
          <div class="container">
            <Bar />
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card min-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Fuentes que más contribuyen a la huella de carbono</v-card-title>
          <div class="container">
            <HorizontalBar />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import Bar from '../ChartsDummy/Bar';
import Doughnut from '../ChartsDummy/Donut';
import HorizontalBar from '../ChartsDummy/HorizontalBar.js';

export default {
  name: 'dashboardPublic',
  components: { Bar, Doughnut, HorizontalBar },
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: ''
  }),
  computed: {
    company() {
      return this.$store.state.general.company;
    }
  },
  watch: {
    company: function (val) {
      this.loading = true;
    }
  }
};
</script>

<style scoped>
.small {
  max-width: 600px;
}
.container.fill-height {
  justify-content: space-around;
  margin: auto;
}
.v-card {
  margin-top: 10px;
  background-color: rgb(227 227 227 / 28%);
}
#card-cards {
  margin-top: 10px;
}
.card {
  display: flex;
  justify-content: space-around;
}
.v-picker__actions {
  background-color: #f1f1f1;
}
</style>
